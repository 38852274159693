define("discourse/plugins/discourse-wikimedia-auth/discourse/components/wiki-username", ["exports", "@ember/component", "@ember/runloop", "@ember/service", "@ember-decorators/component", "jquery", "discourse/lib/decorators", "@ember/template-factory"], function (_exports, _component, _runloop, _service, _component2, _jquery, _decorators, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if this.user.wiki_username}}
    <img
      src="/plugins/discourse-wikimedia-auth/images/wikimedia-logo-black.png"
    />
    <a href={{this.wikiUserUrl}}>{{this.user.wiki_username}}</a>
  {{/if}}
  */
  {
    "id": "ff6P7V6q",
    "block": "[[[41,[30,0,[\"user\",\"wiki_username\"]],[[[1,\"  \"],[10,\"img\"],[14,\"src\",\"/plugins/discourse-wikimedia-auth/images/wikimedia-logo-black.png\"],[12],[13],[1,\"\\n  \"],[10,3],[15,6,[30,0,[\"wikiUserUrl\"]]],[12],[1,[30,0,[\"user\",\"wiki_username\"]]],[13],[1,\"\\n\"]],[]],null]],[],false,[\"if\",\"img\",\"a\"]]",
    "moduleName": "discourse/plugins/discourse-wikimedia-auth/discourse/components/wiki-username.hbs",
    "isStrictMode": false
  });
  const WikiUsername = dt7948.c(class WikiUsername extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "siteSettings", [_service.service]))();
    #siteSettings = (() => (dt7948.i(this, "siteSettings"), void 0))();
    didInsertElement() {
      super.didInsertElement(...arguments);
      (0, _runloop.schedule)("afterRender", () => {
        const $el = (0, _jquery.default)(this.element);
        $el.insertAfter(".full-name");
        (0, _jquery.default)(".full-name").toggleClass("add-margin", Boolean(this.user.name));
      });
    }
    wikiUserUrl(wikiUsername) {
      return `${this.siteSettings.wikimedia_auth_site}/wiki/User:${wikiUsername}`;
    }
    static #_2 = (() => dt7948.n(this.prototype, "wikiUserUrl", [(0, _decorators.default)("user.wiki_username")]))();
  }, [(0, _component2.tagName)("h2"), (0, _component2.classNameBindings)(":wiki-username")]);
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, WikiUsername);
});